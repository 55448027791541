import jwt_decode from "jwt-decode";
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));
  const [tokenExpiration, setTokenExpiration] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [accessLevel, setAccessLevel] = useState(null);
  const isAuthenticated = !!token;


  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedRefreshToken = localStorage.getItem('refreshToken');
    if (storedToken) {
      setToken(storedToken);
      const decodedToken = jwt_decode(storedToken);
      setFirstName(decodedToken.firstname);
      setLastName(decodedToken.lastname);
      setUserId(decodedToken.id);
      setAccessLevel(decodedToken.accesslevel);
    }
    if (storedRefreshToken) {
      setRefreshToken(storedRefreshToken);
    }
  }, []);

  const login = async (newToken, newRefreshToken) => {
    localStorage.setItem('token', newToken);
    localStorage.setItem('refreshToken', newRefreshToken);

    const decodedToken = jwt_decode(newToken);
    const userId = decodedToken.id;
    const userFirstName = decodedToken.firstname;
    const userLastName = decodedToken.lastname;
    const userAccessLevel=decodedToken.accesslevel;

    setToken(newToken);
    setRefreshToken(newRefreshToken);
    setFirstName(userFirstName);
    setLastName(userLastName);
    setUserId(userId);
    setAccessLevel(userAccessLevel);

    const currentTime = Math.floor(Date.now() / 1000);
    const tokenExpiration = decodedToken.exp;
    const timeUntilExpiration = tokenExpiration - currentTime;
    setTimeout(refreshAccessToken, (timeUntilExpiration - 60) * 1000);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    setToken(null);
    setRefreshToken(null);
    setFirstName(null);
    setLastName(null);
    setUserId(null);
    setAccessLevel(null);
  };

  const refreshAccessToken = async () => {
    var rToken=localStorage.getItem('refreshToken');
    try {
      const response = await fetch(process.env.REACT_APP_SERVER_URL+'/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refreshToken: rToken }),
      });

      if (response.ok) {
        const data = await response.json();
        setToken(data.accessToken);
        localStorage.setItem('token', data.accessToken);
      } else {
        logout();
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
    }
  };
    
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        token,
        refreshToken,
        login,
        logout,
        refreshAccessToken,
        firstName,
        lastName,
        userId,
        accessLevel,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
