import React from 'react'
import UserTable from './UserTable';

const UserList = () => {
  return (
    <>
        <h4>Users</h4>
        <UserTable />
    </>
  )
}

export default UserList