import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import DashboardCards from '../components/DashboardCards';
import 'bootstrap/dist/css/bootstrap.min.css';
import PensionersListing from '../components/PensionersListing';

const Dashboard = () => {
  return (
    <div className="px-2 bg-light">
      <Breadcrumbs />
      <div className="">
        <DashboardCards />
      </div>
      <div className="bg-white border rounded my-4 p-2">
          <PensionersListing />
      </div>
    </div>
  )
}

export default Dashboard
