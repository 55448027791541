import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import PrivateRoutes from './components/PrivateRoutes';
import DashboardLayout from './components/DashboardLayout';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import { AuthProvider } from './context/AuthContext';

function App() {
  //const isAuthenticated = true; // Set this based on actual authentication state

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            
            <Route path="/login" element={<Login />} />
            
            <Route element={<PrivateRoutes/>}>
              <Route path="/" element={<DashboardLayout><Dashboard /></DashboardLayout>} />
              <Route path="/dashboard" element={<DashboardLayout><Dashboard /></DashboardLayout>} />
              <Route path="/users" element={<DashboardLayout><Users /></DashboardLayout>} />

          </Route>
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;

