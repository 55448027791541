import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditUserModal = ({ user, show, onHide, onUpdate }) => {
  const [updatedUser, setUpdatedUser] = useState(user);
  const [changePassword, setChangePassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    setUpdatedUser(user);
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser({
      ...updatedUser,
      [name]: value,
    });
  };

  const handlePasswordChange = (e) => {
    setPasswordError('');
    const { value } = e.target;
    setConfirmPassword(value);
  };

  const handleSubmit = () => {
    // Validate fields
    if (!updatedUser.lastname || !updatedUser.firstname || !updatedUser.email || !updatedUser.accesslevel) {
      // Handle validation error
      return;
    }

    if (changePassword && (!updatedUser.password || updatedUser.password !== confirmPassword)) {
      setPasswordError('Passwords do not match');
      return;
    }

    // Proceed with the update
    onUpdate(updatedUser);
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastname"
              value={updatedUser.lastname}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstname"
              value={updatedUser.firstname}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={updatedUser.email}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formAccessLevel">
            <Form.Label>Access Level</Form.Label>
            <Form.Control
              as="select"
              name="accesslevel"
              value={updatedUser.accesslevel}
              onChange={handleInputChange}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formChangePassword">
            <Form.Check
              type="checkbox"
              label="Change Password"
              onChange={() => setChangePassword(!changePassword)}
            />
          </Form.Group>
          {changePassword && (
            <>
              <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={updatedUser.password}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formConfirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  value={confirmPassword}
                  onChange={handlePasswordChange}
                />
                <Form.Text className="text-danger">{passwordError}</Form.Text>
              </Form.Group>
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditUserModal;
