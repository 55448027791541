// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CSS for required fields */
.form-control[required] {
    border: 1px solid red; /* Change the border color */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CreateModal.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;IACI,qBAAqB,EAAE,4BAA4B;EACrD","sourcesContent":["/* CSS for required fields */\n.form-control[required] {\n    border: 1px solid red; /* Change the border color */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
