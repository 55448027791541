import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './PensionersTable.css';
import '@popperjs/core';
import 'bootstrap/dist/js/bootstrap.bundle';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'react-bootstrap';
import CreateUserModal from './CreateUserModal';
import ViewUserModal from './ViewUserModal';
import EditUserModal from './EditUserModal';
import DeleteUserModal from './DeleteUserModal';

const UserTable = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [showCreateUserModal, setShowCreateUserModal] = useState(false);
    const [showViewUserModal, setShowViewUserModal] = useState(false);
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

// Get the JWT token from where it's stored (e.g., state or local storage)
const jwtToken = localStorage.getItem('token'); // Adjust this according to your actual storage method

// Define the headers with the JWT token
const headers = useMemo (() => ({
  Authorization: `Bearer ${jwtToken}`,
}),[jwtToken]);

    useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/users', {headers })
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  },[headers]);

  const fetchUpdatedUsers = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL+'/users',{headers});
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const columns = [
    { dataField: 'lastname', text: 'Last Name', sort: true },
    { dataField: 'firstname', text: 'First Name', sort: true },
    { dataField: 'email', text: 'Email' },
    { dataField: 'accesslevel', text: 'Access Level' },
    
    {
      text: 'Actions',
      formatter: (cell, row) => (
        <div className="d-flex justify-content-between">
          <Button variant="info" className="me-2" onClick={() => handleView(row)}>
          <i class="bi bi-eye-fill"></i> View
          </Button>
          <Button variant="primary" className="me-2"  onClick={() => handleEdit(row)}>
          <i class="bi bi-pencil-fill"></i> Edit
          </Button>
          <Button variant="danger" className="me-2"  onClick={() => handleDelete(row)}>
          <i class="bi bi-trash-fill"></i> Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleView = user => {
    setSelectedUser(user);
    setShowViewUserModal(true);
  };

  const handleEdit = user=> {
    setSelectedUser(user);
    setShowEditUserModal(true);
  };

  const handleDelete = user => {
    setSelectedUser(user);
    setShowDeleteUserModal(true);
  };

  const handleCreate = () => {
    setShowCreateUserModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateUserModal(false);
  };

  const handleCloseViewUserModal = () => {
    setShowViewUserModal(false);
  };

  const handleCloseEditUserModal = () => {
    setShowEditUserModal(false);
  };

  const handleCloseDeleteUserModal = () => {
    setShowDeleteUserModal(false);
  };

  const handleCreateUser = async (userData) => {
    try {
      // Make a POST request to create the user record
      const response = await axios.post(process.env.REACT_APP_SERVER_URL+`/users/`, 
      userData, 
      {
        headers: headers,
      });
      
      if (response.status === 201) {
        console.log('User added successfully:', response.data);
        toast.success("User account created sucessfully.");
        // Fetch updated User data
        fetchUpdatedUsers();
        // Close the modal
        handleCloseCreateModal();
      } else {
        console.log('User create failed:', response.data);
      }
    } catch (error) {
      console.error('Error creating user: ', error);
    }
  };

  const handleUpdateUser = async (userData) => {
    try {
      const response = await axios.put(process.env.REACT_APP_SERVER_URL+`/users/${userData._id}`, 
      userData, 
      {
        headers: headers,
      });

      if (response.status === 200) {
        console.log('User updated successfully:', response.data);
        toast.success('Pensioner record updated successfully');
        // Fetch updated pensioner data
        fetchUpdatedUsers();
        // Close the modal
        handleCloseEditUserModal();
      } else {
        console.log('User create failed:', response.data);
      }

    } catch (error) {
      console.error('Error updating user: ', error);

    }
  };




  return (
    <>
    <ToolkitProvider
      keyField="_id"
      data={users}
      columns={columns}
      search // Enable search feature
    >
      {props => (
        <div>
          <ToastContainer />
          <div className="d-flex align-items-end justify-content-between mb-2">
            <div className="d-flex gap-1">
              <div className="">
              <button className="btn btn-success" onClick={() => handleCreate()}><i className="bi bi-person-plus"></i> Add User</button>
              </div>
              
            </div>
            <div>
              <label className="me-2 mt-4 "><i className="bi bi-search"></i></label>
              <Search.SearchBar {...props.searchProps} placeholder="Search users..." />
            </div>
          </div>
          <BootstrapTable
            {...props.baseProps}
            pagination={paginationFactory()}
            responsive
          />
        </div>
      )}
      
    </ToolkitProvider>

    <CreateUserModal
        show={showCreateUserModal}
        onHide={handleCloseCreateModal}
        onCreateUser={handleCreateUser}
      />
      <ViewUserModal
        show={showViewUserModal}
        onHide={handleCloseViewUserModal}
        user={selectedUser} 
      />
      <EditUserModal
        show={showEditUserModal}
        onHide={handleCloseEditUserModal}
        user={selectedUser} 
        onUpdate={handleUpdateUser}
      />
      <DeleteUserModal 
        show={showDeleteUserModal}
        onHide={handleCloseDeleteUserModal}
        user={selectedUser} 
        fetchUpdatedUsers={fetchUpdatedUsers}
        headers={headers}
      />


    </>
  )
}

export default UserTable