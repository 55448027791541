import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import './EditModal.css';
import moment from 'moment-timezone';

const EditModal = ({ show, onHide, pensioner, fetchUpdatedPensioners }) => {
 
  const [editedPensioner, setEditedPensioner] = useState(pensioner);

  useEffect(()=>{
    setEditedPensioner(pensioner);
  },[pensioner]);

  let currentExpiryDate = moment.tz(editedPensioner.expiryDate,'UTC').format('MMMM Do YYYY');

  const handleFieldChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    // If the field is a checkbox, update the checked property
    if (type === 'checkbox') {
      setEditedPensioner((prevPensioner) => ({
        ...prevPensioner,
        [name]: checked,
      }));
    } else {
      // For other fields, including 'expiryDate', update the value property
      setEditedPensioner((prevPensioner) => ({
        ...prevPensioner,
        [name]: name === 'expiryDate' ? new Date(value) : value, // Parse 'expiryDate' as a Date object
      }));
    }
  };
  

  

  const handleEditSubmit = async () => {
    // Check if required fields are empty
    if (!editedPensioner.lastName || !editedPensioner.firstName || !editedPensioner.nationalInsurance) {
      alert('Please fill in all required fields.');
      return; // Prevent form submission
    }
    
    // Updates the date modified field for database update
    editedPensioner.dateModified= Date();
    
    try {
      // Make a PUT request to update the pensioner record
      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/pensioners/${editedPensioner._id}`, editedPensioner);
      
      if (response.status === 200) {
        console.log('Pensioner updated successfully:', response.data);
        toast.success('Pensioner record updated successfully');
        // Fetch updated pensioner data
        fetchUpdatedPensioners();
        // Clear editedPensioner variable
        setEditedPensioner([]);
        // Close the modal
        onHide();
      } else {
        console.log('Pensioner update failed:', response.data);
        toast.warning('Pensioner update failed');
      }
    } catch (error) {
      console.error('Error updating pensioner:', error);
      toast.error('An error occurred while updating the record');
    }

    
  };

  
  return (
    <Modal show={show} onHide={onHide} >
      <Modal.Header closeButton>
        <Modal.Title>Edit Pensioner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
            <div className="d-flex gap-1">
              <div className="mb-3">
                <label for="inputLastName" className="form-label mb-0">Last Name:</label>
                <input type="text" id="inputLastName" name="lastName" className="form-control" aria-describedby="lastNameHelp" value={editedPensioner.lastName} onChange={handleFieldChange} aria-required required></input>
                <div id="lastNameHelp" className="form-text">Pensioner's Last Name</div>
              </div>
              <div className="mb-3">
                <label for="inputFirstName" className="form-label mb-0">First Name:</label>
                <input type="text" id="inputFirstName" name="firstName" className="form-control" aria-describedby="firstNameHelp" value={editedPensioner.firstName} onChange={handleFieldChange} aria-required required></input>
                <div id="firstNameHelp" className="form-text">Pensioner's First Name</div>
              </div>
            </div>
            <div className="d-flex gap-1">
              <div className="mb-3">
                <label for="inputOtherNames" className="form-label mb-0">Other Names:</label>
                <input type="text" id="inputOtherNames" name="otherNames" className="form-control" aria-describedby="otherNamesHelp" value={editedPensioner.otherNames} onChange={handleFieldChange}></input>
                <div id="otherNamesHelp" className="form-text">Pensioner's Other Names</div>
              </div>
              <div className="mb-3">
                <label for="inputNationalInsurance" className="form-label mb-0">National Insurance:</label>
                <input type="text" id="inputNationalInsurance" name="nationalInsurance" className="form-control" aria-describedby="nationalInsuranceHelp" value={editedPensioner.nationalInsurance} onChange={handleFieldChange} aria-required required></input>
                <div id="nationalInsuranceHelp" className="form-text">Pensioner's National Insurance Number</div>
              </div>
            </div>
            <div >    
              <div className="mb-3 w-50">
                <label for="inputIsland" className="form-label mb-0">Island:</label>
                <input type="text" id="inputIsland" name="island" className="form-control" aria-describedby="islandHelp" value={editedPensioner.island} onChange={handleFieldChange}></input>
                <div id="islandHelp" className="form-text">Pensioner's Island or Country</div>
              </div>
              <div className="mb-3">
              <label for="inputInternational" className="form-label mb-0">International:</label>
              <input type="checkbox" id="inputInternational" name="international" className="form-check-input mx-2" aria-describedby="internationalHelp" checked={editedPensioner.international} onChange={handleFieldChange} ></input>
              <div id="internationalHelp" className="form-text">Is Pensioner/Beneficiary International</div>
            </div>
              <div className="mb-3">
                <label for="inputDeceased" className="form-label mb-0">Deceased:</label>
                <input type="checkbox" id="inputDeceased" name="deceased" className="form-check-input mx-2" aria-describedby="deceasedHelp" checked={editedPensioner.deceased} onChange={handleFieldChange}></input>
                <div id="deceasedHelp" className="form-text">Is Pensioner Deceased</div>
              </div>
            </div>
            <div className="d-flex gap-1">
            <div className="mb-3">
              <label for="inputBeneficiary" className="form-label mb-0">Beneficiary:</label>
              <input type="text" id="inputBeneficiary" name="beneficiary" className="form-control" aria-describedby="beneficiaryHelp" value={editedPensioner.beneficiary} onChange={handleFieldChange}></input>
              <div id="islandHelp" className="form-text">Pensioner's Island or Country</div>
            </div>
            <div className="mb-3">
              <label for="inputBank" className="form-label mb-0">Bank:</label>
              <input type="text" id="inputBank" name="bank" className="form-control" aria-describedby="bankHelp" value={editedPensioner.bank} onChange={handleFieldChange}></input>
              <div id="bankHelp" className="form-text">Pensioner's/Beneficiary's Bank</div>
            </div>
            </div>
            <div className="d-flex gap-2">
              <div className="mb-3 text-small">
                Current Exp. Date: <br /> <b> { editedPensioner.expiryDate == null ? "" : currentExpiryDate }</b>
              </div>
            <div className="mb-3">
              <label for="inputExpiryDate" className="form-label mb-0">Benefit Expiry Date:</label>
              <input type="date" id="inputExpiryDate" name="expiryDate" className="form-control" aria-describedby="expiryDateHelp" value={moment.tz(editedPensioner.expiryDate, 'UTC').format('YYYY-MM-DD')} onChange={handleFieldChange}></input>
              <div id="expiryDateHelp" className="form-text">Benefit Expiry Date</div>
            </div>
            </div>
            <div className="d-flex gap-1">
              <div className="mb-3">
                <label for="inputTelephone" className="form-label mb-0">Telephone:</label>
                <input type="text" id="inputTelephone" name="telephone" className="form-control" aria-describedby="telephoneHelp" value={editedPensioner.telephone} onChange={handleFieldChange}></input>
                <div id="telephoneHelp" className="form-text">Pensioner's/Beneficiary's Telephone</div>
              </div>
              <div className="mb-3">
                <label for="inputEmail" className="form-label mb-0">Email:</label>
                <input type="text" id="inputBank" name="email" className="form-control" aria-describedby="emailHelp" value={editedPensioner.email} onChange={handleFieldChange}></input>
                <div id="emailHelp" className="form-text">Pensioner's/Beneficiary's Email Address</div>
              </div>
            </div>
            <div className="mb-3">
              <label for="inputAddress" className="form-label mb-0">Address:</label>
              <textarea  id="inputAddress" name="address" className="form-control" aria-describedby="emailHelp" value={editedPensioner.address} onChange={handleFieldChange}></textarea>
              <div id="addressHelp" className="form-text">Pensioner's/Beneficiary's Address</div>
            </div>
            <div className="mb-3">
              <label for="inputNotes" className="form-label mb-0">Notes:</label>
              <textarea  id="inputNotes" name="notes" className="form-control" aria-describedby="notesHelp" value={editedPensioner.notes} onChange={handleFieldChange}></textarea>
              <div id="notessHelp" className="form-text">Pensioner's/Beneficiary's Notes</div>
            </div>
            <div className="mb-3">
              <label for="inputActive" className="form-label mb-0">Active:</label>
              <input type="checkbox" id="inputActive" name="active" className="form-check-input mx-2" aria-describedby="activeHelp" checked={editedPensioner.active} onChange={handleFieldChange} ></input>
              <div id="activeHelp" className="form-text">Is Pensioner Active</div>
            </div>
        </div>
        
        
        
      </Modal.Body>
      <Modal.Footer>
        
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleEditSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
