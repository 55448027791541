// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Reduce font size */
.react-bootstrap-table th,
.react-bootstrap-table td {
  font-size: 14px; /* Adjust as needed */
}

/* Style the sorting indicator with Bootstrap icons */
.react-bootstrap-table th.sortable:hover::after,
.react-bootstrap-table th.sortable:focus::after {
  opacity: 1;
  font-size: 0.75rem; /* Adjust the font size */
  margin-left: 0.25rem; /* Add some spacing between the header text and indicator */
  display: inline-block;
  transition: opacity 0.15s;
  font-family: "Arial"; /* Use a font family that supports Bootstrap icons (e.g., "Arial") */
  font-weight: bold;

}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  
  .dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \\9;
    content: "";
  }

  /* Apply background to table header */
.react-bootstrap-table th {
    background-color: #f8f9fa; /* Replace with your desired background color */
  }
 `, "",{"version":3,"sources":["webpack://./src/components/PensionersTable.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;;EAEE,eAAe,EAAE,qBAAqB;AACxC;;AAEA,qDAAqD;AACrD;;EAEE,UAAU;EACV,kBAAkB,EAAE,yBAAyB;EAC7C,oBAAoB,EAAE,2DAA2D;EACjF,qBAAqB;EACrB,yBAAyB;EACzB,oBAAoB,EAAE,oEAAoE;EAC1F,iBAAiB;;AAEnB;;AAEA;IACI,qBAAqB;IACrB,QAAQ;IACR,SAAS;IACT,gBAAgB;IAChB,sBAAsB;IACtB,sBAAsB;IACtB,wBAAwB;IACxB,mCAAmC;IACnC,kCAAkC;EACpC;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,2BAA2B;IAC3B,WAAW;EACb;;EAEA,qCAAqC;AACvC;IACI,yBAAyB,EAAE,+CAA+C;EAC5E","sourcesContent":["/* Reduce font size */\n.react-bootstrap-table th,\n.react-bootstrap-table td {\n  font-size: 14px; /* Adjust as needed */\n}\n\n/* Style the sorting indicator with Bootstrap icons */\n.react-bootstrap-table th.sortable:hover::after,\n.react-bootstrap-table th.sortable:focus::after {\n  opacity: 1;\n  font-size: 0.75rem; /* Adjust the font size */\n  margin-left: 0.25rem; /* Add some spacing between the header text and indicator */\n  display: inline-block;\n  transition: opacity 0.15s;\n  font-family: \"Arial\"; /* Use a font family that supports Bootstrap icons (e.g., \"Arial\") */\n  font-weight: bold;\n\n}\n\n.caret {\n    display: inline-block;\n    width: 0;\n    height: 0;\n    margin-left: 2px;\n    vertical-align: middle;\n    border-top: 4px dashed;\n    border-top: 4px solid \\9;\n    border-right: 4px solid transparent;\n    border-left: 4px solid transparent;\n  }\n  \n  .dropup .caret, .navbar-fixed-bottom .dropdown .caret {\n    border-top: 0;\n    border-bottom: 4px dashed;\n    border-bottom: 4px solid \\9;\n    content: \"\";\n  }\n\n  /* Apply background to table header */\n.react-bootstrap-table th {\n    background-color: #f8f9fa; /* Replace with your desired background color */\n  }\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
