import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Sidebar.css'; 
import logo from "../bhaipf-logo.png";
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { BsArrowLeftRight, BsPerson, BsBuilding, BsFileText } from 'react-icons/bs';



const Sidebar = ({ isCollapsed }) => {
  const { accessLevel } = useAuth();
  const generateNassauVerificationFormsPDF = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL+`/nassauverificationforms/`, {
        responseType: 'blob',
      });
      const pdfUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(pdfUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const generateNassauBeneficiaryVerificationFormsPDF = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL+`/nassaubeneficiaryverificationforms/`, {
        responseType: 'blob',
      });
      const pdfUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(pdfUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const generateIslandBeneficiaryVerificationFormsPDF = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL+`/islandbeneficiaryverificationforms`, {
        responseType: 'blob',
      });
      const pdfUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(pdfUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const generateIslandVerificationFormsPDF = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL+`/islandverificationforms`, {
        responseType: 'blob',
      });
      const pdfUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(pdfUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const generateInternationalBeneficiaryVerificationFormsPDF = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL+`/internationalbeneficiaryverificationforms`, {
        responseType: 'blob',
      });
      const pdfUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(pdfUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const generateInternationalVerificationFormsPDF = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL+`/internationalverificationforms`, {
        responseType: 'blob',
      });
      const pdfUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(pdfUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const generateFreeportVerificationFormsPDF = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL+`/freeportverificationforms`, {
        responseType: 'blob',
      });
      const pdfUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(pdfUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const generateFreeportBeneficiaryVerificationFormsPDF = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL+`/freeportbeneficiaryverificationforms`, {
        responseType: 'blob',
      });
      const pdfUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(pdfUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const renderLogo = () => {
    if (isCollapsed) {
      return <span className="text-dark">BHAIPF</span>;
    } else {
      return <img src={logo} alt="Logo" />;
    }
  };
 
  return (
    <nav className={`sidebar ${isCollapsed ? 'collapsed' : ''} bg-light bg-gradient border rounded`}>
      <div className="logo bg-light bg-gradient text-white py-4 px-2 border-bottom">
        {renderLogo()}
      </div>
      
      <Nav className="flex-column py-4">
        <Nav.Item>
          <Link to="/dashboard" className="nav-link">{isCollapsed ? <span><h3><BsBuilding /></h3></span> : <span><BsBuilding /> Dashboard</span>}</Link>
        </Nav.Item>
        <NavDropdown title={isCollapsed ? <span><h3><BsFileText /></h3></span> : <span><BsFileText /> Verification Forms</span>} id="verification-forms-dropdown">
          <NavDropdown.Item  onClick={generateNassauVerificationFormsPDF}>Nassau Verifications</NavDropdown.Item>
          <NavDropdown.Item onClick={generateNassauBeneficiaryVerificationFormsPDF}>Nassau Beneficiary Verifications</NavDropdown.Item>
          <NavDropdown.Item  onClick={generateFreeportVerificationFormsPDF}>Freeport Verifications</NavDropdown.Item>
          <NavDropdown.Item onClick={generateFreeportBeneficiaryVerificationFormsPDF}>Freeport Beneficiary Verifications</NavDropdown.Item>
          <NavDropdown.Item onClick={generateIslandVerificationFormsPDF}>Island Verifications</NavDropdown.Item>
          <NavDropdown.Item onClick={generateIslandBeneficiaryVerificationFormsPDF}>Island Beneficiary Verifications</NavDropdown.Item>
          <NavDropdown.Item onClick={generateInternationalVerificationFormsPDF}>International Verifications</NavDropdown.Item>
          <NavDropdown.Item onClick={generateInternationalBeneficiaryVerificationFormsPDF}>International Beneficiary Verifications</NavDropdown.Item>
        </NavDropdown>
        <Nav.Item>
          {accessLevel === 1 && (
            <Link to="/users" className="nav-link">{isCollapsed ? <span><h3><BsPerson /></h3></span> : <span><BsPerson /> User Management</span>}</Link>
          )}
        </Nav.Item>
      </Nav>
    </nav>
  );
};

export default Sidebar;
