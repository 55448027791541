import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './Sidebar';
import Header from './Header';

const DashboardLayout = ({ children }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <div className="dashboard-layout d-flex">
      <Sidebar isCollapsed={isSidebarCollapsed} />
      <div className={`main-content flex-grow-1 ${isSidebarCollapsed ? 'ms-4' : ''}`}>
        <Header onSidebarToggle={toggleSidebar} />
        <main>{children}</main>
      </div>
    </div>
  );
};

export default DashboardLayout;
