import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './CreateModal.css';

const CreateModal = ({ show, onHide, pensioner, fetchUpdatedPensioners }) => {
    const [newPensioner, setNewPensioner] = useState([]);



    const handleFieldChange = (e) => {
        const { name, value, type, checked } = e.target;
      
        // If the field is a checkbox, update the checked property
        if (type === 'checkbox') {
          setNewPensioner((prevPensioner) => ({
            ...prevPensioner,
            [name]: checked,
          }));
        } else {
          // For other fields, update the value property
          setNewPensioner((prevPensioner) => ({
            ...prevPensioner,
            [name]: value,
          }));
        }
      };


    const handleCreateSubmit = async () => {
      // Check if required fields are empty
      if (!newPensioner.lastName || !newPensioner.firstName || !newPensioner.nationalInsurance) {
        alert('Please fill in all required fields.');
        return; // Prevent form submission
      }

    try {
      // Make a POST request to update the pensioner record
      const response = await axios.post(process.env.REACT_APP_SERVER_URL+`/pensioners/`, newPensioner);
      
      if (response.status === 201) {
        console.log('Pensioner added successfully:', response.data);
        toast.success("Pensioner record created sucessfully");
        // Fetch updated pensioner data
        fetchUpdatedPensioners();
        // Clear newPensioner data
        setNewPensioner([]);
        // Close the modal
        onHide();
      } else {
        console.log('Pensioner create failed:', response.data);
        toast.warning("Pensioner create failed");
      }
    } catch (error) {
      console.error('Error creating pensioner:', error);
      toast.error("Error creating pensioner record.")
    }

    
  };

  return (
    <Modal show={show} onHide={onHide} >
      <Modal.Header closeButton>
        <Modal.Title>Create Pensioner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
            <div className="d-flex gap-1">
              <div className="mb-3">
                <label for="inputLastName" className="form-label mb-0">Last Name:</label>
                <input type="text" id="inputLastName" name="lastName" className="form-control" aria-describedby="lastNameHelp" onChange={handleFieldChange} aria-required required></input>
                <div id="lastNameHelp" className="form-text">Pensioner's Last Name</div>
              </div>
              <div className="mb-3">
                <label for="inputFirstName" className="form-label mb-0">First Name:</label>
                <input type="text" id="inputFirstName" name="firstName" className="form-control" aria-describedby="firstNameHelp" onChange={handleFieldChange} aria-required required></input>
                <div id="firstNameHelp" className="form-text">Pensioner's First Name</div>
              </div>
            </div>
            <div className="d-flex gap-1">
              <div className="mb-3">
                <label for="inputOtherNames" className="form-label mb-0">Other Names:</label>
                <input type="text" id="inputOtherNames" name="otherNames" className="form-control" aria-describedby="otherNamesHelp" onChange={handleFieldChange}></input>
                <div id="otherNamesHelp" className="form-text">Pensioner's Other Names</div>
              </div>
              <div className="mb-3">
                <label for="inputNationalInsurance" className="form-label mb-0">National Insurance:</label>
                <input type="text" id="inputNationalInsurance" name="nationalInsurance" className="form-control" aria-describedby="nationalInsuranceHelp" onChange={handleFieldChange} aria-required required></input>
                <div id="nationalInsuranceHelp" className="form-text">Pensioner's National Insurance Number</div>
              </div>
            </div>
            <div >    
              <div className="mb-3 w-50">
                <label for="inputIsland" className="form-label mb-0">Island:</label>
                <input type="text" id="inputIsland" name="island" className="form-control" aria-describedby="islandHelp" onChange={handleFieldChange}></input>
                <div id="islandHelp" className="form-text">Pensioner's Island or Country</div>
              </div>
              <div className="mb-3">
              <label for="inputInternational" className="form-label mb-0">International:</label>
              <input type="checkbox" id="inputInternational" name="international" className="form-check-input mx-2" aria-describedby="internationalHelp" onChange={handleFieldChange} ></input>
              <div id="internationalHelp" className="form-text">Is Pensioner/Beneficiary International</div>
            </div>
              <div className="mb-3">
                <label for="inputDeceased" className="form-label mb-0">Deceased:</label>
                <input type="checkbox" id="inputDeceased" name="deceased" className="form-check-input mx-2" aria-describedby="deceasedHelp" onChange={handleFieldChange}></input>
                <div id="deceasedHelp" className="form-text">Is Pensioner Deceased</div>
              </div>
            </div>
            <div className="d-flex gap-1">
            <div className="mb-3">
              <label for="inputBeneficiary" className="form-label mb-0">Beneficiary:</label>
              <input type="text" id="inputBeneficiary" name="beneficiary" className="form-control" aria-describedby="beneficiaryHelp" onChange={handleFieldChange}></input>
              <div id="islandHelp" className="form-text">Pensioner's Island or Country</div>
            </div>
            <div className="mb-3">
              <label for="inputBank" className="form-label mb-0">Bank:</label>
              <input type="text" id="inputBank" name="bank" className="form-control" aria-describedby="bankHelp" onChange={handleFieldChange}></input>
              <div id="bankHelp" className="form-text">Pensioner's/Beneficiary's Bank</div>
            </div>
            </div>
            <div className="d-flex gap-1">
              <div className="mb-3">
                <label for="inputExpiryDate" className="form-label mb-0">Benefit Expiry Date:</label>
                <input type="date" id="inputExpiryDate" name="expiryDate" className="form-control" aria-describedby="expiryDateHelp" onChange={handleFieldChange}></input>
                <div id="expiryDateHelp" className="form-text">Benefit Expiry Date</div>
              </div>
            </div>
            <div className="d-flex gap-1">
              <div className="mb-3">
                <label for="inputTelephone" className="form-label mb-0">Telephone:</label>
                <input type="text" id="inputTelephone" name="telephone" className="form-control" aria-describedby="telephoneHelp" onChange={handleFieldChange}></input>
                <div id="telephoneHelp" className="form-text">Pensioner's/Beneficiary's Telephone</div>
              </div>
              <div className="mb-3">
                <label for="inputEmail" className="form-label mb-0">Email:</label>
                <input type="text" id="inputBank" name="email" className="form-control" aria-describedby="emailHelp" onChange={handleFieldChange}></input>
                <div id="emailHelp" className="form-text">Pensioner's/Beneficiary's Email Address</div>
              </div>
            </div>
            <div className="mb-3">
              <label for="inputAddress" className="form-label mb-0">Address:</label>
              <textarea  id="inputAddress" name="address" className="form-control" aria-describedby="emailHelp" onChange={handleFieldChange}></textarea>
              <div id="addressHelp" className="form-text">Pensioner's/Beneficiary's Address</div>
            </div>
            <div className="mb-3">
              <label for="inputNotes" className="form-label mb-0">Notes:</label>
              <textarea  id="inputNotes" name="notes" className="form-control" aria-describedby="notesHelp" onChange={handleFieldChange}></textarea>
              <div id="notessHelp" className="form-text">Pensioner's/Beneficiary's Notes</div>
            </div>
            <div className="mb-3">
              <label for="inputActive" className="form-label mb-0">Active:</label>
              <input type="checkbox" id="inputActive" name="active" className="form-check-input mx-2" aria-describedby="activeHelp" onChange={handleFieldChange} ></input>
              <div id="activeHelp" className="form-text">Is Pensioner Active</div>
            </div>
        </div>
        
        
        
      </Modal.Body>
      <Modal.Footer>
        
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleCreateSubmit}>
          Save Record
        </Button>
      </Modal.Footer>
    </Modal>

  );
};

export default CreateModal;