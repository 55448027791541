import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Import the AuthContext

const PrivateRoutes = () => {
  const { isAuthenticated } = useAuth(); // Use the isAuthenticated state
  console.log ('private route isAuthenticated: '+isAuthenticated);
return (
  isAuthenticated ? <Outlet /> : <Navigate to="/login" />
  )
}

export default PrivateRoutes;