import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment-timezone';

const ViewModal = ({ show, onHide, pensioner }) => {
  const generatePDF = async (pensionerId) => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL+`/generatepdf/${pensionerId}`, {
        responseType: 'blob',
      });
      const pdfUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(pdfUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const generateBeneficiaryPDF = async (pensionerId) => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL+`/generatepdf/generatebeneficiarypdf/${pensionerId}`, {
        responseType: 'blob',
      });
      const pdfUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(pdfUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  let currentExpiryDate = moment.tz(pensioner.expiryDate,'UTC').format('MMMM Do YYYY');
  

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>View Pensioner Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Display pensioner details here */}
        <p>Last Name: <strong>{pensioner.lastName}</strong></p>
        <p>First Name: <strong>{pensioner.firstName}</strong></p>
        <p>Other Names: <strong>{pensioner.otherNames}</strong></p>
        <p>National Insurance: <strong>{pensioner.nationalInsurance}</strong></p>
        <p>Island: <strong>{pensioner.island}</strong></p>
        <p>International: <input type="checkbox"  checked={pensioner.international} /></p>
        <p>Deceased: <input type="checkbox"  checked={pensioner.deceased} /></p>
        <p>Beneficiary: <strong>{pensioner.beneficiary}</strong></p>
        <p>Bank: <strong>{pensioner.bank}</strong></p>
        <p>Expiry Date: <strong>{pensioner.expiryDate == null ? "" : currentExpiryDate}</strong></p>
        <p>Telephone: <strong>{pensioner.telephone}</strong></p>
        <p>Email: <strong>{pensioner.email}</strong></p>
        <p>Address: <strong>{pensioner.address}</strong></p>
        <p>Notes: <strong>{pensioner.notes}</strong></p>
        <p>Active: <input type="checkbox"  checked={pensioner.active} /></p>
        
      </Modal.Body>
      <Modal.Footer>
        
        <Button variant="primary" onClick={() => {
          if (!pensioner.deceased) { generatePDF(pensioner._id);
          } else {
            generateBeneficiaryPDF(pensioner._id);
          }
        }}>
          Generate Form
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>

    </Modal>
  );
};

export default ViewModal;
