// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DashboardCards.css */
.custom-row {
    margin: -1rem; /* Add negative margin to counteract Bootstrap's default row gutter */
  }
  
.custom-row > div {
    padding: 1rem; /* Add padding to individual columns for spacing */
  }`, "",{"version":3,"sources":["webpack://./src/components/DashboardCards.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,aAAa,EAAE,qEAAqE;EACtF;;AAEF;IACI,aAAa,EAAE,kDAAkD;EACnE","sourcesContent":["/* DashboardCards.css */\n.custom-row {\n    margin: -1rem; /* Add negative margin to counteract Bootstrap's default row gutter */\n  }\n  \n.custom-row > div {\n    padding: 1rem; /* Add padding to individual columns for spacing */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
