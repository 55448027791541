import React from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';

const DeleteModal = ({ show, onHide, pensioner, fetchUpdatedPensioners }) => {
    const handleDelete = async () => {
        if (pensioner) {
          try {
            
            // Make an API call to delete the pensioner record
            const response = await axios.delete(process.env.REACT_APP_SERVER_URL+`/pensioners/${pensioner._id}`);
      
            if (response.status === 200) {
            // Fetch updated pensioner data
            console.log('Pensioner deleted successfully:', response.data);
            toast.success('Record deleted successfully');
            fetchUpdatedPensioners();
      
            // Close the delete confirmation modal
            onHide();
            } else {
                console.log('Pensioner delete failed:', response.data);
                toast.error('Pensioner delete failed');
            }
          } catch (error) {
            console.error('Error deleting pensioner:', error);
            toast.error('An error occurred while deleting the record');
          }
        }
      };

    return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {pensioner && (
            <p>
                Are you sure you want to delete {pensioner.lastName},{' '}
                {pensioner.firstName}, {pensioner.nationalInsurance}?
            </p>
            )}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
            No
            </Button>
            <Button variant="danger" onClick={handleDelete}>
            Yes
            </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default DeleteModal