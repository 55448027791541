import React from 'react'

const Breadcrumbs = () => {
  return (
    <div className="p-2 text-decoration-underline">
      Home/Dashboard
    </div>
  )
}

export default Breadcrumbs
