import React from 'react';
import PensionersTable from './PensionersTable';

const PensionersListing = () => {
  return (
    <div>
      <h4>Pensioners</h4>
      <PensionersTable />
    </div>
  );
};

export default PensionersListing;
