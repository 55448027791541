import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Col, Row } from 'react-bootstrap'; // Import the Card, Col, and Row components
import 'bootstrap/dist/css/bootstrap.min.css';
import './DashboardCards.css';


const DashboardCards = () => {
  const [activePensioners, setActivePensioners] = useState([]);
  const [deceasedPensioners, setDeceasedPensioners] = useState([]);
  const [activeBeneficiaries, setActiveBeneficiaries] = useState([]);
  const [inactiveBeneficiaries, setInactiveBeneficiaries] = useState([]);
  const [nassauActive, setNassauActive] = useState([]);
  const [nassauDeceased, setNassauDeceased] = useState([]);
  const [nassauInactive, setNassauInactive] = useState([]);
  const [islandActive, setIslandActive] = useState([]);
  const [islandDeceased, setIslandDeceased] = useState([]);
  const [islandInactive, setIslandInactive] = useState([]);
  const [internationalActive, setInternationalActive] = useState([]);
  const [internationalDeceased, setInternationalDeceased] = useState([]);
  const [internationalInactive, setInternationalInactive] = useState([]);
  const [freeportActive, setFreeportActive] = useState([]);
  const [freeportDeceased, setFreeportDeceased] = useState([]);
  const [freeportInactive, setFreeportInactive] = useState([]);
  

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/pensioners/activepensioners')
      .then(response => {
        setActivePensioners(response.data);
      })
      .catch(error => {
        console.error('Error fetching pensioners:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/pensioners/deceasedpensioners')
      .then(response => {
        setDeceasedPensioners(response.data);
      })
      .catch(error => {
        console.error('Error fetching pensioners:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/pensioners/activebeneficiaries')
      .then(response => {
        setActiveBeneficiaries(response.data);
      })
      .catch(error => {
        console.error('Error fetching beneficiaries:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/pensioners/inactivebeneficiaries')
      .then(response => {
        setInactiveBeneficiaries(response.data);
      })
      .catch(error => {
        console.error('Error fetching beneficiaries:', error);
      });
  }, []);
  
  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/nassau/nassauactive')
      .then(response => {
        setNassauActive(response.data);
      })
      .catch(error => {
        console.error('Error fetching Nassau active pensioners:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/nassau/nassaudeceased')
      .then(response => {
        setNassauDeceased(response.data);
      })
      .catch(error => {
        console.error('Error fetching Nassau deceased pensioners:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/nassau/nassauinactive')
      .then(response => {
        setNassauInactive(response.data);
      })
      .catch(error => {
        console.error('Error fetching Nassau inactive pensioners:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/island/islandactive')
      .then(response => {
        setIslandActive(response.data);
      })
      .catch(error => {
        console.error('Error fetching Island active pensioners:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/island/islanddeceased')
      .then(response => {
        setIslandDeceased(response.data);
      })
      .catch(error => {
        console.error('Error fetching Island deceased pensioners:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/island/islandinactive')
      .then(response => {
        setIslandInactive(response.data);
      })
      .catch(error => {
        console.error('Error fetching Island inactive pensioners:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/international/internationalactive')
      .then(response => {
        setInternationalActive(response.data);
      })
      .catch(error => {
        console.error('Error fetching International active pensioners:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/international/internationaldeceased')
      .then(response => {
        setInternationalDeceased(response.data);
      })
      .catch(error => {
        console.error('Error fetching International deceased pensioners:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/international/internationalinactive')
      .then(response => {
        setInternationalInactive(response.data);
      })
      .catch(error => {
        console.error('Error fetching International inactive pensioners:', error);
      });
  }, []);
  
  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/freeport/freeportactive')
      .then(response => {
        setFreeportActive(response.data);
      })
      .catch(error => {
        console.error('Error fetching Freeport active pensioners:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/freeport/freeportdeceased')
      .then(response => {
        setFreeportDeceased(response.data);
      })
      .catch(error => {
        console.error('Error fetching Freeport deceased pensioners:', error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/freeport/freeportinactive')
      .then(response => {
        setInternationalInactive(response.data);
      })
      .catch(error => {
        console.error('Error fetching Freeport inactive pensioners:', error);
      });
  }, []);


  
  return (
    <>
    <Row xs={1} md={2} lg={4} className="g-4 custom-row mx-2">
      {/* xs={1} for extra small screens (1 card per row) */}
      {/* md={2} for medium screens (2 cards per row) */}
      {/* lg={4} for large screens (4 cards per row) */}
      <Col>
        <Card className="bg-primary bg-gradient text-white border rounded" style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title className="text-center border-bottom">Pensioners</Card.Title>
            <Card.Text>
              <div className="d-flex justify-content-center gap-4">
                <div>
                  <div className="text-center"><center>Active</center></div>
                  <div className="text-center"><center>{activePensioners.length}</center></div>
                </div>
                <div>
                  <div className="text-center">Deceased</div>
                  <div className="text-center">{deceasedPensioners.length}</div>
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col>
        <Card className="bg-warning bg-gradient text-white border rounded" style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title className="text-center border-bottom ">Beneficiaries</Card.Title>
            <Card.Text>
            <div className="d-flex justify-content-center gap-4">
                <div>
                  <div className="text-center"><center>Active</center></div>
                  <div className="text-center"><center>{activeBeneficiaries.length}</center></div>
                </div>
                <div>
                  <div className="text-center">Inactive</div>
                  <div className="text-center">{inactiveBeneficiaries.length}</div>
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col>
        <Card className="bg-success bg-gradient text-white border rounded" style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title className="text-center border-bottom ">Nassau</Card.Title>
            <Card.Text>
              <div className="d-flex justify-content-center gap-4">
                <div>
                  <div className="text-center"><center>Active</center></div>
                  <div className="text-center"><center>{nassauActive.length}</center></div>
                </div>
                <div>
                  <div className="text-center">Deceased</div>
                  <div className="text-center">{nassauDeceased.length}</div>
                </div>
                <div>
                  <div className="text-center"><center>Inactive</center></div>
                  <div className="text-center"><center>{nassauInactive.length}</center></div>
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Col>
        <Card className="bg-info bg-gradient text-white border rounded" style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title className="text-center border-bottom ">Freeport</Card.Title>
            <Card.Text>
            <div className="d-flex justify-content-center gap-4">
                <div>
                  <div className="text-center"><center>Active</center></div>
                  <div className="text-center"><center>{freeportActive.length}</center></div>
                </div>
                <div>
                  <div className="text-center">Deceased</div>
                  <div className="text-center">{freeportDeceased.length}</div>
                </div>
                <div>
                  <div className="text-center"><center>Inactive</center></div>
                  <div className="text-center"><center>{freeportInactive.length}</center></div>
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row xs={1} md={2} lg={4} className="g-4 custom-row mx-2">
      {/* xs={1} for extra small screens (1 card per row) */}
      {/* md={2} for medium screens (2 cards per row) */}
      {/* lg={4} for large screens (4 cards per row) */}
      <Col>
        <Card className="bg-danger bg-gradient text-white border rounded" style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title className="text-center border-bottom ">Islands/Other</Card.Title>
            <Card.Text>
            <div className="d-flex justify-content-center gap-4">
                <div>
                  <div className="text-center"><center>Active</center></div>
                  <div className="text-center"><center>{islandActive.length}</center></div>
                </div>
                <div>
                  <div className="text-center">Deceased</div>
                  <div className="text-center">{islandDeceased.length}</div>
                </div>
                <div>
                  <div className="text-center"><center>Inactive</center></div>
                  <div className="text-center"><center>{islandInactive.length}</center></div>
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    <Col>
        <Card className="bg-secondary bg-gradient text-white border rounded" style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title className="text-center border-bottom ">International</Card.Title>
            <Card.Text>
            <div className="d-flex justify-content-center gap-4">
                <div>
                  <div className="text-center"><center>Active</center></div>
                  <div className="text-center"><center>{internationalActive.length}</center></div>
                </div>
                <div>
                  <div className="text-center">Deceased</div>
                  <div className="text-center">{internationalDeceased.length}</div>
                </div>
                <div>
                  <div className="text-center"><center>Inactive</center></div>
                  <div className="text-center"><center>{internationalInactive.length}</center></div>
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>

    </Row>

  </>
  );
};

export default DashboardCards;
