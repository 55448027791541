import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ViewModal from './ViewModal'; 
import EditModal from './EditModal';
import DeleteModal from './DeleteModal';
import CreateModal from './CreateModal';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './PensionersTable.css';
import '@popperjs/core';
import 'bootstrap/dist/js/bootstrap.bundle';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'react-bootstrap';
import * as XLSX from 'xlsx/xlsx.mjs';

const { SearchBar } = Search; // Destructure the SearchBar component

const PensionersTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPensioner, setSelectedPensioner] = useState([]);
  const [pensioners, setPensioners] = useState([]);

  const fetchUpdatedPensioners = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL+'/pensioners/pensioners');
      setPensioners(response.data);
    } catch (error) {
      console.error('Error fetching pensioners:', error);
    }
  };

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER_URL+'/pensioners/pensioners')
      .then(response => {
        setPensioners(response.data);
      })
      .catch(error => {
        console.error('Error fetching pensioners:', error);
      });
  }, []);

  function exportToExcel(data, columns, fileName) {
    const worksheetData = data.map((item) => {
      const rowData = {};
      columns.forEach((column) => {
        // Use the column accessor as the property key
        const key = column.accessor;
        rowData[key] = item[key];
      });
      return rowData;
    });
  
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }

  const excelColumns = [
    {
      Header: 'Last Name',
      accessor: 'lastName',
      exportAccessor: (row) => row.lastName, // Accessor function for exporting
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      exportAccessor: (row) => row.firstName, // Accessor function for exporting
    },
    {
      Header: 'Other Names',
      accessor: 'otherNames',
      exportAccessor: (row) => row.otherNames, // Accessor function for exporting
    },
    {
      Header: 'National Insurance',
      accessor: 'nationalInsurance',
      exportAccessor: (row) => row.nationalInsurance, // Accessor function for exporting
    },
    {
      Header: 'Deceased',
      accessor: 'deceased',
      exportAccessor: (row) => row.deceased, // Accessor function for exporting
    },
    {
      Header: 'Beneficiary',
      accessor: 'beneficiary',
      exportAccessor: (row) => row.beneficiary, // Accessor function for exporting
    },
    {
      Header: 'Expiry Date',
      accessor: 'expiryDate',
      exportAccessor: (row) => row.expiryDate, // Accessor function for exporting
    },
    {
      Header: 'Telephone',
      accessor: 'telephone',
      exportAccessor: (row) => row.telephone, // Accessor function for exporting
    },
    {
      Header: 'Email',
      accessor: 'email',
      exportAccessor: (row) => row.email, // Accessor function for exporting
    },
    {
      Header: 'Address',
      accessor: 'address',
      exportAccessor: (row) => row.address, // Accessor function for exporting
    },
    {
      Header: 'Island',
      accessor: 'island',
      exportAccessor: (row) => row.island, // Accessor function for exporting
    },
    {
      Header: 'International',
      accessor: 'international',
      exportAccessor: (row) => row.international, // Accessor function for exporting
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      exportAccessor: (row) => row.notes, // Accessor function for exporting
    },
    {
      Header: 'Active',
      accessor: 'active',
      exportAccessor: (row) => row.active, // Accessor function for exporting
    },
  ];
  const columns = [
    { dataField: 'lastName', text: 'Last Name', sort: true },
    { dataField: 'firstName', text: 'First Name', sort: true },
    { dataField: 'nationalInsurance', text: 'National Insurance', },
    // Add more columns as needed
    {
      text: 'Actions',
      formatter: (cell, row) => (
        <div className="d-flex justify-content-between">
          <Button variant="info" className="me-2" onClick={() => handleView(row)}>
          <i class="bi bi-eye-fill"></i> View
          </Button>
          <Button variant="primary" className="me-2"  onClick={() => handleEdit(row)}>
          <i class="bi bi-pencil-fill"></i> Edit
          </Button>
          <Button variant="danger" className="me-2"  onClick={() => handleDelete(row)}>
          <i class="bi bi-trash-fill"></i> Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleView = pensioner => {
    setSelectedPensioner(pensioner);
    setShowModal(true);
  };

  const handleEdit = pensioner => {
    setSelectedPensioner(pensioner);
    setShowEditModal(true);
  };

  const handleDelete = pensioner => {
    setSelectedPensioner(pensioner);
    setShowDeleteModal(true);
  };

  const handleCreate = () => {
    setShowCreateModal(true);
  };

  const handleTableDownload = (data, columns) => {
    exportToExcel(data, columns, 'pensioners_table');
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowEditModal(false);
    setShowCreateModal(false);
    setShowDeleteModal(false);
    setSelectedPensioner([]);
  };

  return (
    <ToolkitProvider
      keyField="_id"
      data={pensioners}
      columns={columns}
      search // Enable search feature
    >
      {props => (
        <div>
          <ToastContainer />
          <div className="d-flex align-items-end justify-content-between mb-2">
            <div className="d-flex gap-1">
              <div className="">
              <button className="btn btn-success" onClick={() => handleCreate()}><i className="bi bi-person-plus"></i> Add Pensioner</button>
              </div>
              <div>
              <button className="btn btn-success" onClick={() => handleTableDownload(pensioners, excelColumns)}><i className="bi bi-file-earmark-spreadsheet"></i> Download Table</button>
              </div>
            </div>
            <div>
              <label className="me-2 mt-4 "><i className="bi bi-search"></i></label>
              <Search.SearchBar {...props.searchProps} placeholder="Search pensioners..." />
            </div>
          </div>
          <BootstrapTable
            {...props.baseProps}
            pagination={paginationFactory()}
            responsive
          />
        {/* View Modal */}
      <ViewModal show={showModal} onHide={handleCloseModal} pensioner={selectedPensioner} />
      <EditModal show={showEditModal} onHide={handleCloseModal}  fetchUpdatedPensioners={fetchUpdatedPensioners} pensioner={selectedPensioner} />
      <DeleteModal show={showDeleteModal} onHide={handleCloseModal}  fetchUpdatedPensioners={fetchUpdatedPensioners} pensioner={selectedPensioner} />
      <CreateModal show={showCreateModal} onHide={handleCloseModal}  fetchUpdatedPensioners={fetchUpdatedPensioners} />
      <ToastContainer />
        </div>
      )}
      
    </ToolkitProvider>

    

  );
};

export default PensionersTable;
